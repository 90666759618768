import Parse from "parse";














export class Materialdisposition extends Parse.Object {
  constructor(data) {
    super("Materialdisposition", data );
  }
}

Parse.Object.registerSubclass("Materialdisposition", Materialdisposition);
