const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelInfoRoute.tsx";import * as React from "react";

import { observer } from "mobx-react-lite";

import { Route, Routes } from "react-router-dom";

import { ArtikelInfoSearchRoute } from "./ArtikelInfoSearchRoute";
import { ArtikelInfoShowRoute } from "./ArtikelInfoShowRoute";



export const ArtikelInfoRoute = observer(function ArtikelInfoRoute() {
  return (
    React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , React.createElement(Route, { element: React.createElement(ArtikelInfoSearchRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
      , React.createElement(Route, { path: ":artikel", element: React.createElement(ArtikelInfoShowRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
    )
  );
});
