const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelInfoSearchRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { observer } from "mobx-react-lite";

import { Link } from "react-router-dom";
import { Button, Divider, Input, List, PageHeader } from "antd";
import { useParseQuery } from "parse-hooks";

import * as Parse from "parse";

export const ArtikelInfoSearchRoute = observer(
  function ArtikelInfoSearchRoute() {
    const [searchString, setSearchString] = React.useState("");
    const [searchStringDebounce, setSearchStringDebounce] = React.useState("");

    const query = React.useMemo(
      () =>
        searchStringDebounce
          ? new Parse.Query("Artikel").contains(
              "suchindex",
              searchStringDebounce.toLowerCase()
            )
          : null,
      [searchStringDebounce]
    );

    const result = useParseQuery(query);

    return (
      React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , React.createElement(PageHeader, {
          onBack: () => {
            window.history.back();
          },
          title: "Artikelinformationen",
          subTitle: "Geben Sie die Artikelnummer oder eine Bezeichnung ein", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
        , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , React.createElement(Input, {
            placeholder: "Artikelnummer oder Bezeichnung"  ,
            style: { width: "100%" },
            value: searchString,
            onChange: (e) => {
              setSearchString(e.target.value);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          )
          , React.createElement(Button, {
            type: "primary",
            style: { marginTop: "10px", width: "100%" },
            disabled: !searchString || _optionalChain([searchString, 'optionalAccess', _ => _.length]) === 0,
            onClick: () => {
              setSearchStringDebounce(searchString);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , "Suchen"

          )

          , !!searchStringDebounce && (
            React.createElement(React.Fragment, null
              , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )

              , React.createElement(List, {
                loading: result.loading,
                rowKey: (row) => row.id,
                dataSource: result.result,
                renderItem: (row) => {
                  return (
                    React.createElement(Link, { to: `/artikel/${row.get("artikelnummer")}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
                      , React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
                        , React.createElement(List.Item.Meta, {
                          title: row.get("bezeichnung"),
                          description: row.get("artikelnummer"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                        )
                      )
                    )
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
              )
            )
          )
        )
      )
    );
  }
);
