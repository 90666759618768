 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message, Modal } from "antd";
import { makeAutoObservable, runInAction } from "mobx";

import * as Parse from "parse";

import {
  Artikel,
  ArtikelBestand,
  ArtikelEinlagerung,
  Auftrag,
  Auftragsposition,
  Auftragsstueckliste,
  AuftragsstuecklistenPosition,
  Buchung,
  Lager,
  Lagerort,
  open_transaction_artikel,
} from "../types-generated";


















export class ArtikelEntnahmeState {
   __init() {this.loading = true}

  

   __init2() {this.buchung = new open_transaction_artikel({
    user: Parse.User.current(),
    menge: 1,
  })}

   __init3() {this.buchungen = []}

   __init4() {this.bestaende = {}}
   __init5() {this.einlagerungen = {}}
   __init6() {this.selectedBestandId = undefined}
   __init7() {this.selectedEinlagerungId = undefined}
   __init8() {this.lagerValid = false}

   __init9() {this.mengeRelevant = false}
   __init10() {this.mengeSoll = 0}
   __init11() {this.mengeIst = 0}
   __init12() {this.mengeFehlt = 0}
   __init13() {this.menge = 1}
   __init14() {this.maxMenge = 0}

   __init15() {this.rücklagerung = false}
   __init16() {this.showConfirmDialog = false}
   __init17() {this.showBuchungen = false}
   __init18() {this.locked = false}

   __init19() {this.seriennummer = ""}
   __init20() {this.charge = ""}

  

  constructor(config, onSave) {;ArtikelEntnahmeState.prototype.__init.call(this);ArtikelEntnahmeState.prototype.__init2.call(this);ArtikelEntnahmeState.prototype.__init3.call(this);ArtikelEntnahmeState.prototype.__init4.call(this);ArtikelEntnahmeState.prototype.__init5.call(this);ArtikelEntnahmeState.prototype.__init6.call(this);ArtikelEntnahmeState.prototype.__init7.call(this);ArtikelEntnahmeState.prototype.__init8.call(this);ArtikelEntnahmeState.prototype.__init9.call(this);ArtikelEntnahmeState.prototype.__init10.call(this);ArtikelEntnahmeState.prototype.__init11.call(this);ArtikelEntnahmeState.prototype.__init12.call(this);ArtikelEntnahmeState.prototype.__init13.call(this);ArtikelEntnahmeState.prototype.__init14.call(this);ArtikelEntnahmeState.prototype.__init15.call(this);ArtikelEntnahmeState.prototype.__init16.call(this);ArtikelEntnahmeState.prototype.__init17.call(this);ArtikelEntnahmeState.prototype.__init18.call(this);ArtikelEntnahmeState.prototype.__init19.call(this);ArtikelEntnahmeState.prototype.__init20.call(this);
    makeAutoObservable(this);

    this.config = config;
    this.onSave = onSave;

    // console.log("ArtikelEntnahmeState", this);

    // window.state = this;

    this.fetchData();
  }

  get artikel() {
    return this.buchung.get("artikel") ;
  }

  get currentTab() {
    if (this.showBuchungen) {
      return 3;
    }

    if (this.rücklagerung) {
      return 2;
    }

    return 1;
  }

   setTab(tab) {
    tab = Math.min(3, Math.max(tab, 1));

    if (tab === 1) {
      this.rücklagerung = false;
      this.showBuchungen = false;

      this.setMenge(1);
    }

    if (tab === 2) {
      this.rücklagerung = true;
      this.showBuchungen = false;

      this.setMenge(1);
      this.setBestand(undefined);
    }

    if (tab === 3) {
      this.showBuchungen = true;
    }

    this.setMenge(this.menge);
  }

   setMenge(menge) {
    this.menge = menge;

    if (this.rücklagerung) {
      this.buchung.set("menge", menge * -1);
    } else {
      this.buchung.set("menge", menge);
    }
  }

   setBestand(value) {
    const bestand = this.bestaende[value ];

    if (bestand) {
      const lager = bestand.get("lager") ;

      this.selectedBestandId = value;
      this.selectedEinlagerungId = undefined;

      this.buchung.set("lager", lager);
      this.lagerValid = true;

      this.maxMenge = bestand.get("bestandLaufend") || 0;

      this.setMenge(Math.min(this.maxMenge, this.menge));
    } else {
      this.selectedBestandId = undefined;
      this.selectedEinlagerungId = undefined;
      this.buchung.set("lager", undefined);
      this.buchung.set("lagerort", undefined);
      this.lagerValid = false;
      this.maxMenge = 9999;
    }
  }

   setEinlagerung(value) {
    const einlagerung = this.einlagerungen[value ];

    if (einlagerung) {
      const lager = einlagerung.get("lager") ;
      const lagerort = einlagerung.get("lagerort") ;

      this.selectedEinlagerungId = value;

      this.buchung.set("lager", lager);
      this.buchung.set("lagerort", lagerort);
      this.lagerValid = true;

      this.maxMenge = einlagerung.get("menge") || 0;
      this.setMenge(Math.min(this.maxMenge, this.menge));
    } else {
      this.selectedBestandId = undefined;
      this.selectedEinlagerungId = undefined;
      this.buchung.set("lager", undefined);
      this.buchung.set("lagerort", undefined);
      this.lagerValid = false;
      this.maxMenge = 9999;
    }
  }

   setSeriennummer(value) {
    this.seriennummer = value;
    this.buchung.set("seriennummer", value);
  }

   setCharge(value) {
    this.charge = value;
    this.buchung.set("charge", value);
  }

   setConfirmDialog(value) {
    this.showConfirmDialog = value;
  }

   async validateLagerId(value) {
    const parts =
      value.length === 12
        ? [value.slice(0, 6), value.slice(6, 12)]
        : value.length === 6
        ? [value]
        : value.length === 13 && value.includes("/")
        ? value.split("/")
        : [];

    try {
      if (parts.length === 1) {
        const lager = await new Parse.Query(Lager)
          .equalTo("sqlRef", parts[0])
          .first();

        if (lager) {
          runInAction(() => {
            this.lagerValid = true;

            this.buchung.set("lager", lager);
          });
        } else {
          throw new Error("Kein Lager gefunden");
        }
      } else if (parts.length === 2) {
        const lager = await new Parse.Query(Lager)
          .equalTo("sqlRef", parts[0])
          .first();

        const lagerort = await new Parse.Query(Lagerort)
          .equalTo("sqlRef", JSON.stringify(parts))
          .first();

        if (!lager || !lagerort) {
          throw new Error("Lager oder Lagerort nicht gefudnen");
        }

        runInAction(() => {
          this.lagerValid = true;

          this.buchung.set("lager", lager);
          this.buchung.set("lagerort", lagerort);
        });
      } else {
        throw new Error("Unbekanntes Format");
      }
    } catch (err) {
      runInAction(() => {
        this.lagerValid = false;
      });
    }
  }

   async buchen(confimed = false) {
    if (!confimed) {
      this.showConfirmDialog = true;
      return;
    }

    this.showConfirmDialog = false;
    this.locked = true;

    const hide = message.loading("Buchung wird durchgeführt...", 0);

    if (this.onSave) this.onSave();

    await this.buchung.save();

    for (let i = 0; i < 10; i++) {
      await delay(1000);

      await this.buchung.fetch();

      if (this.buchung.get("gebucht")) {
        break;
      }
    }

    hide();

    if (this.buchung.get("gebucht")) {
      if (this.buchung.get("successful")) {
        message.success("Erfolgreich gebucht");
      } else {
        Modal.error({
          title: "Fehler in der Buchung",
          content: _optionalChain([this, 'access', _ => _.buchung, 'access', _2 => _2.get, 'call', _3 => _3("response"), 'optionalAccess', _4 => _4.answer]),
        });
      }
    } else {
      message.error("Auftrag wurde nicht gebucht");
    }
  }

   async fetchData() {
    if (this.config.type === "Artikel") {
      this.fetchArtikel();
    }

    if (this.config.type === "Auftragsposition") {
      this.fetchAuftragsposition();
    }

    if (this.config.type === "AuftragsstuecklistenPosition") {
      this.fetchAuftragsstuecklistenPosition();
    }
  }

   async fetchArtikel() {
    try {
      if (this.config.type !== "Artikel") {
        throw new Error("Invalid function call");
      }

      const artikel = await new Parse.Query(Artikel).get(this.config.artikelId);

      const auftragsposition = new Auftragsposition({
        id: this.config.auftragpositionId,
      });

      const auftrag = new Auftrag({
        id: this.config.auftragId,
      });

      const stückliste = new Auftragsstueckliste({
        id: this.config.stückliste,
      });

      const buchungen = await new Parse.Query(Buchung)
        .equalTo("artikel", artikel)
        .equalTo("auftragsstueckliste", stückliste)
        .include("artikel")
        .descending("updatedAt")
        .find();

      runInAction(() => {
        this.buchung.set("geplant", false);
        this.buchung.set("artikel", artikel);
        this.buchung.set("geplant", false);

        if (auftragsposition.id) {
          this.buchung.set("auftragposition", auftragsposition);
        }

        if (auftrag.id) {
          this.buchung.set("auftrag", auftrag);
        }

        if (stückliste.id) {
          this.buchung.set("auftragsstueckliste", stückliste);
        }

        this.buchungen = buchungen;

        this.loading = false;
      });

      await this.fetchLager();
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
   async fetchAuftragsposition() {
    try {
      if (this.config.type !== "Auftragsposition") {
        throw new Error("Invalid function call");
      }

      const auftragsposition = await new Parse.Query(Auftragsposition)
        .include("artikel")
        .include("auftrag")
        .get(this.config.id);

      const artikel = auftragsposition.get("artikel");
      const auftrag = auftragsposition.get("auftrag");

      const buchungen = await new Parse.Query(Buchung)
        .equalTo("artikel", artikel)
        .equalTo("auftragsPosition", auftragsposition)
        .include("artikel")
        .descending("updatedAt")
        .find();

      runInAction(() => {
        this.buchung.set("geplant", true);
        this.buchung.set("artikel", artikel);
        this.buchung.set("auftragposition", auftragsposition);
        this.buchung.set("auftrag", auftrag);

        this.buchungen = buchungen;

        this.mengeRelevant = true;
        this.mengeSoll = auftragsposition.get("mengeSoll") || 0;
        this.mengeIst = auftragsposition.get("mengeIst") || 0;
        this.mengeFehlt = Math.max(this.mengeSoll - this.mengeIst, 0);

        this.loading = false;
      });

      await this.fetchLager();
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

   async fetchAuftragsstuecklistenPosition() {
    try {
      if (this.config.type !== "AuftragsstuecklistenPosition") {
        throw new Error("Invalid function call");
      }

      const obj = await new Parse.Query(AuftragsstuecklistenPosition)
        .include("artikel")
        .include("auftragsstueckliste")

        // .include("auftragsstueckliste.auftrag")
        .include("artikel")
        .get(this.config.id);

      const artikel = obj.get("artikel") ;
      const auftragsstueckliste = obj.get(
        "auftragsstueckliste"
      ) ;
      const auftrag = auftragsstueckliste.get("auftrag") ;

      const buchungen = await new Parse.Query(Buchung)
        .equalTo("artikel", artikel)
        .equalTo("auftragsstuecklistenPosition", obj)
        .include("artikel")
        .descending("updatedAt")
        .find();

      runInAction(() => {
        this.buchung.set("geplant", obj.get("fertig") ? false : true);
        this.buchung.set("artikel", artikel);
        this.buchung.set("auftragsstuecklistenPosition", obj);
        this.buchung.set("auftragsstueckliste", auftragsstueckliste);
        this.buchung.set("auftrag", auftrag);

        this.buchungen = buchungen;

        this.mengeRelevant = true;
        this.mengeSoll = obj.get("mengeSoll") || obj.get("menge") || 0;
        this.mengeIst = obj.get("mengeIst") || 0;
        this.mengeFehlt = Math.max(this.mengeSoll - this.mengeIst, 0);

        this.loading = false;
      });

      await this.fetchLager();
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

   async fetchLager() {
    try {
      const bestaende = await new Parse.Query(ArtikelBestand)
        .equalTo("artikel", this.artikel)
        .greaterThan("bestandLaufend", 0)
        .include("lager")
        .find();

      const einlagerungen = await new Parse.Query(ArtikelEinlagerung)
        .equalTo("artikel", this.artikel)
        .greaterThan("menge", 0)
        .include("lager")
        .include("lagerort")
        .find();

      runInAction(() => {
        this.bestaende = Object.fromEntries(
          bestaende.map((parseObject) => [parseObject.id, parseObject])
        );

        this.einlagerungen = Object.fromEntries(
          einlagerungen.map((parseObject) => [parseObject.id, parseObject])
        );

        if (_optionalChain([bestaende, 'access', _5 => _5[0], 'optionalAccess', _6 => _6.id])) {
          this.setBestand(_optionalChain([bestaende, 'access', _7 => _7[0], 'optionalAccess', _8 => _8.id]));
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  toJSON() {
    return {
      selectedBestandId: this.selectedBestandId,
      selectedEinlagerungId: this.selectedEinlagerungId,
      lagerValid: this.lagerValid,

      mengeRelevant: this.mengeRelevant,
      mengeSoll: this.mengeSoll,
      mengeIst: this.mengeIst,
      mengeFehlt: this.mengeFehlt,
      menge: this.menge,
      maxMenge: this.maxMenge,

      rücklagerung: this.rücklagerung,
      showConfirmDialog: this.showConfirmDialog,
      showBuchungen: this.showBuchungen,

      seriennummer: this.seriennummer,
      charge: this.charge,

      config: this.config,
    };
  }
}

function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}
