const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\forms\\components\\ImageCam.js";import React, { } from "react";

import { Form, Input, Typography, Button } from "antd";
import FileInputComponent from "react-file-input-previews-base64";


const { Text } = Typography;

function ImageCam(props) {
  let style = { width: "100%", display: "inline-block" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  const formData = props.FormData;

  const imageToDataUri = (imga) => {
    const img = new Image();
    img.src = imga;
    img.onload = () => {
      const elem = document.createElement("canvas");

      //scale 2000x1000
      var newScaleWidth = 0;
      var newScaleHeight = 0;
      if (img.width >= img.height) {
        if (img.width > 1920) {
          var scaler = 1920 / img.width;
          newScaleWidth = img.width * scaler;
          newScaleHeight = img.height * scaler;
        } else {
          newScaleWidth = img.width;
          newScaleHeight = img.height;
        }
      } else {
        if (img.height > 1920) {
          var scaler = 1920 / img.height;
          newScaleWidth = img.width * scaler;
          newScaleHeight = img.height * scaler;
        } else {
          newScaleWidth = img.width;
          newScaleHeight = img.height;
        }
      }

      elem.width = newScaleWidth;
      elem.height = newScaleHeight;

      const ctx = elem.getContext("2d");
      ctx.drawImage(img, 0, 0, newScaleWidth, newScaleHeight);
      const data = ctx.canvas.toDataURL("image/jpeg", 0.7);

      let newForm = {};
      newForm[state.key] = data;
      formData.setFieldsValue(newForm);
    };
  };
  let state = props.Data;

  let rules = [];
  let options = state.options || {};
  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        return Promise.resolve();
      },
    }));
  }

  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
      , React.createElement(Form.Item, {
        className: "hidden_custom_form",
        name: state.key,
        label: React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, state.title),
        rules: rules,
        style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
      
        , React.createElement(Input, { hidden: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}})
      )
      , React.createElement(FileInputComponent, {
        labelText: "",
        labelStyle: { fontSize: 20 },
        multiple: false,
        imageContainerStyle: {
          textAlign: "center",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          paddingBottom: "5px",
        },
        buttonComponent: 
          React.createElement(Button, {
            style: { width: "100%" },
            type: "file",
            accept: "image/*;capture=camera", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          , "Foto/Bild auswählen"

          )
        ,
        callbackFunction: (file_arr) => {
          imageToDataUri(file_arr.base64);
        },
        accept: "image/*", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      )
    )
  );
}
export default ImageCam;
