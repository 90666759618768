import Parse from "parse";















export class ArtikelBestand extends Parse.Object {
  constructor(data) {
    super("ArtikelBestand", data );
  }
}

Parse.Object.registerSubclass("ArtikelBestand", ArtikelBestand);
