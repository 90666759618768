import Parse from "parse";



























export class open_transaction_stckl extends Parse.Object {
  constructor(data) {
    super("open_transaction_stckl", data );
  }
}

Parse.Object.registerSubclass("open_transaction_stckl", open_transaction_stckl);
