import Parse from "parse";

















export class open_transaction_arbeit extends Parse.Object {
  constructor(data) {
    super("open_transaction_arbeit", data );
  }
}

Parse.Object.registerSubclass("open_transaction_arbeit", open_transaction_arbeit);
