const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\components\\Loading.js";import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = React.createElement(LoadingOutlined, { style: { fontSize: 100 }, spin: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}} );

function Loading() {
  return (
    React.createElement('div', { style: { position: "relative", height: "100vh" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
      , React.createElement(Spin, {
        style: {
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
        indicator: antIcon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      )
    )
  );
}

export default Loading;
