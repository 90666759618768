const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelEntnahme.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { observer } from "mobx-react-lite";

import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import {
  Space,
  Select,
  Button,
  InputNumber,
  Divider,
  Tag,
  Modal,
  Result,
  Tabs,
  Badge,
  Input,
  Alert,
  Table,
  Spin,
} from "antd";

import { Icon } from "@opendash/icons";

import {
  ArtikelEntnahmeState,

} from "./ArtikelEntnahme.state";
import { $wehn } from "../state";







export const ArtikelEntnahme = observer(function ArtikelEntnahme({
  config,
  onSave,
  onCancel,
}) {
  const state = React.useMemo(
    () => new ArtikelEntnahmeState(config, onSave),
    []
  );

  const navigate = useNavigate();

  const tabSwipeHandler = useSwipeable({
    onSwipedRight: (eventData) => {
      state.setTab(state.currentTab - 1);
    },
    onSwipedLeft: (eventData) => {
      state.setTab(state.currentTab + 1);
    },
  });

  if (state.loading) {
    return React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ConfirmDialog, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )

      , React.createElement('div', { style: { width: "100%" }, ...tabSwipeHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, _optionalChain([state, 'access', _2 => _2.artikel, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("artikelnummer")]))

        , React.createElement('span', { style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          , React.createElement(Badge, {
            count: `${state.mengeIst} / ${state.mengeSoll}`,
            style: { backgroundColor: "#12bd45", float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
          )
        )

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} )
        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} )

        , React.createElement(Button, {
          onClick: () => {
            $wehn.buchung.closeDrawer();
            navigate(`/artikel/${_optionalChain([state, 'access', _5 => _5.artikel, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("artikelnummer")])}`);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        
          , _optionalChain([state, 'access', _8 => _8.artikel, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("bezeichnung")]), " "
          , React.createElement(Icon, { icon: "fa:external-link-square", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} )
        , React.createElement(Tabs, {
          activeKey: "" + state.currentTab,
          onTabClick: (key, event) => {
            state.setTab(parseInt(key));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
        
          , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Entnahme"), key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
            , React.createElement(Dialog, { key: "1", state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )
          )
          , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}, "Rücklagerung"), key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
            , React.createElement(Dialog, { key: "2", state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}} )
          )
          , React.createElement(Tabs.TabPane, {
            tab: 
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, "Buchungen"
                , " "
                , React.createElement(Badge, {
                  count: state.buchungen.length || 0,
                  style: { backgroundColor: "#cccccc" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
                )
              )
            ,
            key: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          
            , React.createElement(Table, {
              size: "small",
              dataSource: state.buchungen,
              rowKey: (row) => row.id,
              columns: [
                // {
                //   title: "Nutzer",
                //   key: "user",
                //   render: (_, row) =>
                //     row.get("user")?.get("amsCardCode") || "Unbekannter Nutzer",
                // },
                {
                  title: "Art",
                  key: "art",
                  render: (_, row) => (
                    React.createElement(Tag, { color: row.get("menge") > 0 ? "green" : "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
                      , row.get("menge") > 0 ? "E" : "R"
                    )
                  ),
                },
                {
                  title: "Menge",
                  key: "menge",
                  render: (_, row) => (
                    React.createElement('b', {
                      style: {
                        color: row.get("menge") < 0 ? "red" : "green",
                        float: "right",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
                    
                      , row.get("menge")
                    )
                  ),
                },
                {
                  title: "Einheit",
                  key: "einheit",
                  render: (_, row) => _optionalChain([row, 'access', _11 => _11.get, 'call', _12 => _12("artikel"), 'optionalAccess', _13 => _13.get, 'call', _14 => _14("mengeneinheit")]),
                },
              ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
            )
          )
        )
      )
    )
  );
});

const Dialog = observer

(({ state }) => {
  const bestaendeSelectOptions = React.useMemo(
    () =>
      Object.values(state.bestaende).map((x) => ({
        label: ` ${_optionalChain([x, 'access', _15 => _15.get, 'call', _16 => _16("lager"), 'optionalAccess', _17 => _17.get, 'call', _18 => _18("sqlRef")])} - ${x.get(
          "bestandLaufend"
        )} ${_optionalChain([state, 'access', _19 => _19.artikel, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("mengeneinheit")])}`,
        value: x.id,
      })),
    [state.selectedBestandId]
  );

  const einlagerungenSelectOptions = React.useMemo(
    () =>
      state.selectedBestandId
        ? Object.values(state.einlagerungen)
            .filter(
              (x) => _optionalChain([x, 'access', _22 => _22.get, 'call', _23 => _23("lager"), 'optionalAccess', _24 => _24.id]) === _optionalChain([state, 'access', _25 => _25.buchung, 'access', _26 => _26.get, 'call', _27 => _27("lager"), 'optionalAccess', _28 => _28.id])
            )
            .map((x) => ({
              label: `Lagerort ${_optionalChain([x, 'access', _29 => _29.get, 'call', _30 => _30("lager"), 'optionalAccess', _31 => _31.get, 'call', _32 => _32("sqlRef")])} / ${_optionalChain([x
, 'access', _33 => _33.get, 'call', _34 => _34("lagerort")
, 'optionalAccess', _35 => _35.get, 'call', _36 => _36("lagerort")])} - ${x.get("menge")} ${_optionalChain([state, 'access', _37 => _37.artikel, 'optionalAccess', _38 => _38.get, 'call', _39 => _39(
                "mengeneinheit"
              )])}`,
              value: x.id,
            }))
        : [],
    [state.selectedEinlagerungId, state.selectedBestandId]
  );

  React.useEffect(() => {
    if (einlagerungenSelectOptions.length > 0) {
      state.setEinlagerung(_optionalChain([einlagerungenSelectOptions, 'access', _40 => _40[0], 'optionalAccess', _41 => _41.value]));
    }
  }, [einlagerungenSelectOptions]);

  if (bestaendeSelectOptions.length === 0) {
    return React.createElement(Result, { status: "warning", title: "Artikel ist nicht mehr auf Lager"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}} );
  }

  if (state.locked) {
    return React.createElement(Result, { status: "info", title: "Buchung wurde abgeschickt"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}} );
  }

  return (
    React.createElement(React.Fragment, null
      , !state.rücklagerung && (
        React.createElement(React.Fragment, null
          , bestaendeSelectOptions.length > 0 && (
            React.createElement(Select, {
              size: "large",
              style: { width: "100%" },
              placeholder: "Bestand auswählen" ,
              options: bestaendeSelectOptions,
              value: state.selectedBestandId,
              onChange: (id) => {
                state.setBestand(id);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}
            )
          )

          , einlagerungenSelectOptions.length > 0 && (
            React.createElement(Select, {
              size: "large",
              style: { width: "100%", marginTop: 10 },
              placeholder: "Einlagerung auswählen" ,
              options: einlagerungenSelectOptions,
              value: state.selectedEinlagerungId,
              onChange: (id) => {
                state.setEinlagerung(id);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
            )
          )
        )
      )

      , state.rücklagerung && (
        React.createElement(React.Fragment, null
          , React.createElement(Input, {
            placeholder: "Lager scannen" ,
            allowClear: true,
            style: { width: "100%" },
            onChange: (e) => {
              state.validateLagerId(e.target.value);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
          )
        )
      )

      , (state.selectedBestandId || state.rücklagerung) && (
        React.createElement(React.Fragment, null
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 257}})
          , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
            , _optionalChain([state, 'access', _42 => _42.artikel, 'optionalAccess', _43 => _43.get, 'call', _44 => _44("chargenverfolgung")]) && (
              React.createElement(Input, {
                placeholder: "Chargennummer",
                style: { width: "100%" },
                value: state.charge,
                onChange: (e) => {
                  state.setCharge(e.target.value);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}
              )
            )

            , _optionalChain([state, 'access', _45 => _45.artikel, 'optionalAccess', _46 => _46.get, 'call', _47 => _47("seriennummer")]) && (
              React.createElement(React.Fragment, null
                , React.createElement(Input, {
                  placeholder: "Seriennummer",
                  style: { width: "100%" },
                  value: state.seriennummer,
                  onChange: (e) => {
                    state.setSeriennummer(e.target.value);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}
                )
                , React.createElement(Button, {
                  type: "primary",
                  size: "large",
                  style: { width: "100%" },
                  disabled: 
                    !state.lagerValid ||
                    (state.rücklagerung
                      ? 1 > state.mengeIst
                      : 1 > state.maxMenge)
                  ,
                  onClick: () => {
                    state.setMenge(1);
                    state.buchen();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}
                , "Artikel mit Seriennummer entnehmen"

                )
              )
            )

            , !_optionalChain([state, 'access', _48 => _48.artikel, 'optionalAccess', _49 => _49.get, 'call', _50 => _50("seriennummer")]) && (
              React.createElement(React.Fragment, null
                , React.createElement(InputNumber, {
                  type: "number",
                  value: state.menge,
                  onChange: (test) => {
                    if (test === null) {
                      state.setMenge(0);
                    } else {
                      state.setMenge(test);
                    }
                  },
                  placeholder: "Zu entnehmende Menge"  ,
                  style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 302}}
                )

                , (state.rücklagerung
                  ? state.menge === 0 || state.menge > state.mengeIst
                  : state.menge === 0 || state.menge > state.maxMenge) && (
                  React.createElement(Alert, { message: "Bitte Menge kontrollieren"  , type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}} )
                )

                , React.createElement(Button, {
                  type: "primary",
                  size: "large",
                  style: { width: "100%" },
                  disabled: 
                    !state.lagerValid ||
                    (state.rücklagerung
                      ? state.menge === 0 || state.menge > state.mengeIst
                      : state.menge === 0 || state.menge > state.maxMenge)
                  ,
                  onClick: () => {
                    state.buchen();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 322}}
                
                  , state.rücklagerung ? "Rücklagern" : "Entnehmen"
                )
              )
            )
          )
        )
      )
    )
  );
});

const ConfirmDialog = observer

(({ state }) => {
  React.useEffect(() => {
    if (state.showConfirmDialog) {
      const spanStyle = { marginBottom: 10 };
      const dateEntryStyle = { float: "right" };

      const okButtonStyle = state.buchung.get("geplant")
        ? {
            backgroundColor: "green",
            color: "white",
            borderColor: "darkgreen",
          }
        : {
            backgroundColor: "#ffe58f",
            color: "#faad14",
            borderColor: "#faad14",
          };

      Modal.confirm({
        title:
          (state.rücklagerung ? "Rücklagerung " : "Entnahme ") +
          _optionalChain([state, 'access', _51 => _51.artikel, 'optionalAccess', _52 => _52.get, 'call', _53 => _53("artikelnummer")]),
        icon: state.buchung.get("geplant") ? (
          React.createElement(Icon, { icon: "fa:check-circle", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 372}} )
        ) : (
          React.createElement(Icon, { icon: "fa:exclamation-circle", style: { color: "#faad14" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 374}} )
        ),
        okButtonProps: { style: okButtonStyle },
        okText:
          "" +
          Math.abs(state.menge) +
          " " +
          _optionalChain([state, 'access', _54 => _54.artikel, 'optionalAccess', _55 => _55.get, 'call', _56 => _56("mengeneinheit")]) +
          (state.rücklagerung ? " rücklagern" : " entnehmen"),
        cancelText: "abbrechen",
        content: (
          React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}, _optionalChain([state, 'access', _57 => _57.artikel, 'optionalAccess', _58 => _58.get, 'call', _59 => _59("bezeichnung")]))
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 387}} )
            , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 388}}, "Geplante Menge:"
               , " "
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 390}}
                , state.mengeSoll, " " , _optionalChain([state, 'access', _60 => _60.artikel, 'optionalAccess', _61 => _61.get, 'call', _62 => _62("mengeneinheit")])
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 394}} )

            , state.seriennummer && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}}, "Seriennummer:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}, "t", state.seriennummer)
              )
            )

            , state.charge && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 404}}, "Charge:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 406}}, "t", state.charge)
              )
            )
            , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 409}}, "Bereits entnommen:"
               , " "
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 411}}
                , state.mengeIst || 0, " " , _optionalChain([state, 'access', _63 => _63.artikel, 'optionalAccess', _64 => _64.get, 'call', _65 => _65("mengeneinheit")])
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 415}} )
            , !state.rücklagerung && (
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}, "Lager-Verfügbarkeit :"
                 , " "
                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 419}}
                  , state.maxMenge, " " , _optionalChain([state, 'access', _66 => _66.artikel, 'optionalAccess', _67 => _67.get, 'call', _68 => _68("mengeneinheit")])
                )
              )
            )
            , state.rücklagerung && (
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 425}}, "Lager:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 427}}
                  , _optionalChain([state, 'access', _69 => _69.buchung, 'access', _70 => _70.get, 'call', _71 => _71("lager"), 'optionalAccess', _72 => _72.get, 'call', _73 => _73("sqlRef")]) || ""
                )
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 432}} )
            , state.rücklagerung && (
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 434}}, "Lagerort:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 436}}
                  , _optionalChain([state, 'access', _74 => _74.buchung, 'access', _75 => _75.get, 'call', _76 => _76("lagerort"), 'optionalAccess', _77 => _77.get, 'call', _78 => _78("lagerort")]) || ""
                )
              )
            )
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 441}})
            , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 442}}
              , state.rücklagerung ? "Rücklagerung:" : "Entnahme:"
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 444}}
                , state.menge, " " , _optionalChain([state, 'access', _79 => _79.artikel, 'optionalAccess', _80 => _80.get, 'call', _81 => _81("mengeneinheit")])
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 448}} )
          )
        ),
        onOk() {
          state.buchen(true);
        },
        onCancel() {
          state.setConfirmDialog(false);
        },
      });
    }
  }, [state.showConfirmDialog]);

  return null;
});
