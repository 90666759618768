import Parse from "parse";












export class Lager extends Parse.Object {
  constructor(data) {
    super("Lager", data );
  }
}

Parse.Object.registerSubclass("Lager", Lager);
