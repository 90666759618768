const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelEntnahmeDrawer.tsx";import * as React from "react";

import { observer } from "mobx-react-lite";

import { $wehn } from "../state";
import { ArtikelEntnahme } from "./ArtikelEntnahme";
import { Drawer } from "antd";

export const ArtikelEntnahmeDrawer = observer(function ArtikelEntnahmeDrawer() {
  if ($wehn.buchung.drawer) {
    return (
      // @ts-ignore
      React.createElement(Drawer, {
        title: "Buchung",
        visible: true,
        height: window.innerHeight,
        placement: "bottom",
        onClose: () => {
          $wehn.buchung.closeDrawer();
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
      
        , React.createElement(ArtikelEntnahme, {
          config: $wehn.buchung.drawer,
          onSave: () => {
            $wehn.buchung.closeDrawer();
          },
          onCancel: () => {
            $wehn.buchung.closeDrawer();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        )
      )
    );
  }

  return React.createElement(Drawer, { title: "Buchung", visible: false, placement: "bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} );
});
