import Parse from "parse";











export class UserMeta extends Parse.Object {
  constructor(data) {
    super("UserMeta", data );
  }
}

Parse.Object.registerSubclass("UserMeta", UserMeta);
