import Parse from "parse";





















export class Auftragsstueckliste extends Parse.Object {
  constructor(data) {
    super("Auftragsstueckliste", data );
  }
}

Parse.Object.registerSubclass("Auftragsstueckliste", Auftragsstueckliste);
