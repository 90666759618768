const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\TimeSheetComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState } from "react";
import Parse from "parse";
import produce from "immer";
import { List, Tabs, Button, Divider, Drawer, Radio, Select } from "antd";
import moment from "moment";
import { useSwipeable } from "react-swipeable";

import Loading from "../components/Loading";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Icon } from "@opendash/icons";

const { TabPane } = Tabs;
const { Option } = Select;

function TimeSheetComponent(props) {
  const [state, setState] = React.useState({
    data: { items: [], removals: [] },
    order: props.id,
  });
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  //Set Extras of PageHeader
  React.useEffect(() => {
    props.SetExtras([]);
    update();
  }, []);
  const [currentTab, setCurrentTab] = useState(1);
  const update = async () => {
    let auftragQuery = new Parse.Query("Auftrag");
    auftragQuery.equalTo("auftragsNummer", searchParams.get("reference"));

    let auftrag = await auftragQuery.find();

    let aufPosQuery = new Parse.Query("Auftragsposition");
    aufPosQuery.equalTo("auftragPosition", params.unit);

    aufPosQuery.equalTo("auftrag", auftrag[0]);

    let aufPos = await aufPosQuery.find();
    if (!auftrag[0] || !aufPos[0]) {
      setState(
        produce((draft) => {
          draft.info = {
            text: "Auftrag bzw. Position nicht gefunden",
            description:
              "Der von ihnen gewählte Auftrag bzw. die Position konnte im System nicht gefunden werden",
            type: "error",
          };
        })
      );
      return;
    }

    let listenQuery = new Parse.Query("Auftragsstueckliste");

    auftrag.id = props.Reference;
    aufPos.id = props.Unit;
    listenQuery.equalTo("auftrag", auftrag[0]);
    listenQuery.equalTo("auftragPosition", aufPos[0]);
    let stcklisten = await listenQuery.find();
    let positionQuery = new Parse.Query("AuftragsarbeitsplaenePosition");
    positionQuery.equalTo("auftragsstueckliste", stcklisten[0]);
    let positionen = await positionQuery.find();
    let openTransactionsQuery = new Parse.Query("open_transaction_arbeit");
    openTransactionsQuery.equalTo("auftrag", auftrag[0]);
    openTransactionsQuery.equalTo("auftragposition", aufPos[0]);
    let openTransactions = await openTransactionsQuery.find();
    setState(
      produce((draft) => {
        draft.order = auftrag[0];
        draft.position = aufPos[0];
        draft.stcklPos = stcklisten[0];
        draft.filter = "";
        draft.data = {
          operations: positionen.map((pos) => {
            return pos.toJSON();
          }),
          timeRecordings: openTransactions.map((pos) => {
            return pos.toJSON();
          }),
        };
      })
    );
  };

  const TimeSheetItem = (props) => {
    const item = props.Item;
    if (!item) return "emtpy";
    return (
      React.createElement(List.Item, { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        , React.createElement(List.Item.Meta, {
          avatar: React.createElement(Icon, { icon: "fa:clock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} ),
          title: "Arbeitsgang " + item.agnr + " - " + item.bezeichnung,
          description: 
            "Vorgesehene Arbeitszeit: " +
            (item.sollzeit > 1
              ? Math.floor(item.sollzeit) + " Stunden und "
              : "") +
            parseInt((item.sollzeit * 60) % 60) +
            " Minuten"
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
        , React.createElement(Button, {
          icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} ),
          type: "primary",
          onClick: () => {
            setState(
              produce((draft) => {
                draft.selectedOperation = item;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , "Erfassen"

        )
      )
    );
  };
  const tabPaneStyle = { background: "white" };

  const TimePicker = (props) => {
    const item = props.Item;
    const existing = props.ExistingTransactionId;
    const [pickerState, setPickerState] = useState({
      mode: "single",
      now: moment(props.Start),
      end: props.End
        ? moment(props.End)
        : moment(props.Start).add(
            Math.min(_optionalChain([props, 'access', _ => _.Item, 'optionalAccess', _2 => _2.sollzeit]) || 1, 8),
            "hour"
          ),
    });
    React.useEffect(() => {
      if (props.Item) checkTransactions();
    }, [props.Item]);
    if (!props.Item) return "";

    if (existing && !pickerState.existingTransaction) {
      return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 142}} );
    }
    const checkTransactions = async () => {
      let q = new Parse.Query("open_transaction_arbeit");
      q.equalTo("user", Parse.User.current());
      q.equalTo("reference", state.stcklPos);
      q.equalTo("agnr", props.Item.agnr);
      if (existing) {
        q.equalTo("objectId", existing);
      } else {
        q.doesNotExist("end");
      }
      let x = await q.first();
      if (x) {
        setPickerState(
          produce((draft) => {
            const now = x.get("start");
            const end = x.get("end");
            if (now && end) {
              draft.mode = "complete";
            }
            draft.now = moment(now);
            draft.end = end ? moment(end) : moment();
            draft.saveObj = x;
          })
        );
      }
    };

    const getPicker = (end) => {
      const now = !end ? pickerState.now : pickerState.end;
      if (!now) return "";
      const prefix = !end ? props.PrefixStart : props.PrefixEnd;
      const suffix = !end ? props.SuffixStart : props.SuffixEnd;
      const setHours = (e) => {
        setPickerState(
          produce((draft) => {
            let newNow = moment(now);
            newNow.set({ hour: e });
            if (!end) {
              draft.now = newNow;
            } else {
              draft.end = newNow;
            }
          })
        );
      };
      const setMinutes = (e) => {
        setPickerState(
          produce((draft) => {
            let newNow = moment(now);
            newNow.set({ minute: e });
            if (!end) {
              draft.now = newNow;
            } else {
              draft.end = newNow;
            }
          })
        );
      };
      return (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
          , end ? React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 204}} ) : ""
          , React.createElement('div', { className: "timespan_picker_single", __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
            , prefix
            , React.createElement(Select, {
              listItemHeight: 80,
              listHeight: 320,
              size: "large",
              value: now.hours(),
              onChange: setHours,
              bordered: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
            
              , [...Array(24).keys()].map((hour) => {
                return (
                  React.createElement(Option, { value: hour, __self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}, hour < 10 ? "0" + hour : hour)
                );
              })
            )
            , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}, ":")
            , React.createElement(Select, {
              className: "timepicker",
              listItemHeight: 80,
              listHeight: 320,
              size: "large",
              value: now.minutes(),
              onChange: setMinutes,
              bordered: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 222}}
            
              , [...Array(60).keys()].map((min) => {
                return (
                  React.createElement(Option, { value: min, __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}, min < 10 ? "0" + min : min)
                );
              })
            )
            , suffix
          )
        )
      );
    };

    return (
      React.createElement('div', { id: "timepicker_drawer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
        , React.createElement(Radio.Group, {
          style: { width: "100vw" },
          options: [
            { label: "Einzelerfassung", value: "single" },
            { label: "Start & Ende", value: "complete" },
          ],
          onChange: (e) => {
            setPickerState(
              produce((draft) => {
                draft.mode = e.target.value;
              })
            );
          },
          value: pickerState.mode,
          optionType: "button",
          buttonStyle: "solid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 245}}
        )
        , React.createElement('div', {
          id: "timespan_picker",
          style: {
            margin: "20px",
            width: "calc(100% - 40px)",
            textAlign: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}
        
          , pickerState.mode === "complete" ||
          !pickerState.saveObj ||
          pickerState.saveObj.get("end")
            ? getPicker()
            : ""
          , pickerState.mode === "complete" || pickerState.saveObj
            ? getPicker(true)
            : ""
        )
        , React.createElement(Button, {
          style: {
            width: "calc(100% - 40px)",
            position: "absolute",
            bottom: "20px",
          },
          onClick: () => {
            let toSave = pickerState.saveObj;
            let storeEnd = true;
            if (!toSave) {
              toSave = new Parse.Object("open_transaction_arbeit");
              toSave.set("user", Parse.User.current());
              storeEnd = false;
            }
            toSave.set("start", pickerState.now.toDate());
            if (storeEnd) toSave.set("end", pickerState.end.toDate());
            toSave.set("agnr", item.agnr);
            toSave.set("reference", state.stcklPos);
            toSave.save().then((res) => {
              props.onFinisih();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
        , "Zeit erfassen"

        )
      )
    );
  };

  const tabSwipeHandler = useSwipeable({
    onSwipedRight: (eventData) => {
      setCurrentTab(Math.max(currentTab - 1, 1));
    },
    onSwipedLeft: (eventData) => {
      setCurrentTab(Math.min(currentTab + 1, 2));
    },
  });
  const drawerClose = () => {
    setState(
      produce((draft) => {
        delete draft.selectedOperation;
      })
    );
  };

  return (
    React.createElement('div', { ...tabSwipeHandler, className: "fullHeight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 325}}
      , React.createElement(Tabs, {
        activeKey: "" + currentTab,
        onTabClick: (key, event) => {
          setCurrentTab(parseInt(key));
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 326}}
      
        , React.createElement(TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}, "Arbeitsgänge"), key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}
          , React.createElement('div', { style: tabPaneStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}
            , React.createElement(List, {
              itemLayout: "horizontal",
              dataSource: state.data.operations,
              renderItem: (item) => React.createElement(TimeSheetItem, { Item: item, __self: this, __source: {fileName: _jsxFileName, lineNumber: 337}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 334}}
            )
          )
        )
        , React.createElement(TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}, "Erfasste Zeiten" ), key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
          , React.createElement('div', { style: tabPaneStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}, "Tab 2" )
        )
      )
      , React.createElement(Drawer, {
        placement: "bottom",
        title: 
          "Zeiten eingeben " + (_optionalChain([state, 'access', _3 => _3.selectedOperation, 'optionalAccess', _4 => _4.bezeichnung]) || "")
        ,
        visible: state.selectedOperation,
        closable: "true",
        onClose: drawerClose,
        height: "50%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 345}}
      
        , React.createElement(TimePicker, {
          PrefixStart: "Beginn:",
          SuffixStart: " Uhr" ,
          PrefixEnd: "Ende:",
          SuffixEnd: " Uhr" ,
          Start: moment(),
          End: null,
          Item: state.selectedOperation,
          onFinisih: drawerClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}}
        )
      )
    )
  );
}
export default TimeSheetComponent;
