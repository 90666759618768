import Parse from "parse";



























export class Buchung extends Parse.Object {
  constructor(data) {
    super("Buchung", data );
  }
}

Parse.Object.registerSubclass("Buchung", Buchung);
