import Parse from "parse";





































export class open_transaction_artikel extends Parse.Object {
  constructor(data) {
    super("open_transaction_artikel", data );
  }
}

Parse.Object.registerSubclass("open_transaction_artikel", open_transaction_artikel);
