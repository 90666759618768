import { makeAutoObservable } from "mobx";




export class BuchungService {
   __init() {this.buchungen = []}
   __init2() {this.drawer = null}

  constructor() {;BuchungService.prototype.__init.call(this);BuchungService.prototype.__init2.call(this);
    makeAutoObservable(this);
  }

  openDrawer(config) {
    this.drawer = config;
  }

  closeDrawer() {
    this.drawer = null;
  }
}
