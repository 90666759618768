import Parse from "parse";
































export class Auftragsposition extends Parse.Object {
  constructor(data) {
    super("Auftragsposition", data );
  }
}

Parse.Object.registerSubclass("Auftragsposition", Auftragsposition);
