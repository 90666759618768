const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\common.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Parse from "parse";
import React from "react";

import {
  Modal,
  message,
  Alert,
  List,
  Row,
  Col,
  Space,
  Statistic,
  Badge,
  Tag,
} from "antd";
import { Icon } from "@opendash/icons";
export async function createArtikelTransaction(item) {
  const transaction = new Parse.Object("open_transaction_artikel");

  //Set Auftrag
  if (_optionalChain([item, 'access', _ => _.auftrag, 'optionalAccess', _2 => _2.objectId])) {
    transaction.set(
      "auftrag",
      new Parse.Object("Auftrag", { id: item.auftrag.objectId })
    );
  }

  if (_optionalChain([item, 'access', _3 => _3.auftragsstueckliste, 'optionalAccess', _4 => _4.auftrag, 'optionalAccess', _5 => _5.objectId])) {
    transaction.set(
      "auftrag",
      new Parse.Object("Auftrag", {
        id: item.auftragsstueckliste.auftrag.objectId,
      })
    );
  }

  //Set Position
  if (_optionalChain([item, 'access', _6 => _6.auftragsposition, 'optionalAccess', _7 => _7.objectId])) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.auftragsposition.objectId,
      })
    );
  }

  /*
  if (item.auftragsstueckliste?.auftragPosition?.objectId) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.auftragsstueckliste.auftragPosition.objectId,
      })
    );
  }
  */
  //Set Stückliste
  if (_optionalChain([item, 'access', _8 => _8.auftragsstueckliste, 'optionalAccess', _9 => _9.objectId])) {
    transaction.set(
      "auftragsstueckliste",
      new Parse.Object("Auftragsstueckliste", {
        id: item.auftragsstueckliste.objectId,
      })
    );
  }

  if (item.objectId && "auftragPosition" in item) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.objectId,
      })
    );
  } else if (item.objectId) {
    transaction.set(
      "auftragsstuecklistenPosition",
      new Parse.Object("AuftragsstuecklistenPosition", {
        id: item.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _10 => _10.artikel, 'optionalAccess', _11 => _11.objectId])) {
    transaction.set(
      "artikel",
      new Parse.Object("Artikel", {
        id: item.artikel.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _12 => _12.lager, 'optionalAccess', _13 => _13.objectId])) {
    transaction.set(
      "lager",
      new Parse.Object("Lager", {
        id: item.lager.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _14 => _14.lagerort, 'optionalAccess', _15 => _15.objectId])) {
    transaction.set(
      "lagerort",
      new Parse.Object("Lagerort", {
        id: item.lagerort.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _16 => _16.lagerorte, 'optionalAccess', _17 => _17[0], 'optionalAccess', _18 => _18.lager, 'optionalAccess', _19 => _19.objectId])) {
    transaction.set(
      "lager",
      new Parse.Object("Lager", {
        id: item.lagerorte[0].lager.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _20 => _20.lagerorte, 'optionalAccess', _21 => _21[0], 'optionalAccess', _22 => _22.lagerort, 'optionalAccess', _23 => _23.objectId])) {
    transaction.set(
      "lagerort",
      new Parse.Object("Lagerort", {
        id: item.lagerorte[0].lagerort.objectId,
      })
    );
  }

  if (item.seriennummer) {
    transaction.set("seriennummer", item.seriennummer);
  }

  if (item.charge) {
    transaction.set("charge", item.charge);
  }

  if ("auftragsstuecklistenPosition" in item) {
    const listpos = await item.get("auftragsstuecklistenPosition").fetch();
    soll = listpos.get("mengeSoll") || 0;
    ist = listpos.get("mengeIst") || 0;
    fertig = listpos.get("fertig");
  }
  let geplant =
    !(
      "auftragsstuecklistenPosition" in item &&
      item.auftragsstuecklistenPosition
    ) || !(ist >= soll || fertig);
  geplant = item["position"] !== "ungeplant" ? geplant : false;

  transaction.set("menge", item.menge);
  transaction.set("geplant", geplant);
  transaction.set("user", Parse.User.current());
  await transaction.save();

  for (let i = 0; i < 10; i++) {
    await delay(1000);

    await transaction.fetch();

    if (transaction.get("gebucht")) {
      break;
    }
  }

  if (transaction.get("gebucht")) {
    if (transaction.get("successful")) {
      message.success("Erfolgreich gebucht");
    } else {
      Modal.error({
        title: "Fehler in der Buchung",
        content: _optionalChain([transaction, 'access', _24 => _24.get, 'call', _25 => _25("response"), 'optionalAccess', _26 => _26.answer]),
      });
    }
  } else {
    message.error("Auftrag wurde nicht gebucht");
  }

  return transaction;
}

export async function undoArtikelTransaction(removal, menge) {
  const a = new Parse.Object("open_transaction_artikel", {
    id: removal.objectId,
  });

  await a.fetch();
  a.set("lager", removal.lager);
  a.set("lagerort", removal.lagerort);
  let fertig = false;
  let ist = 0;
  let soll = 0;
  if (a.has("auftragposition")) {
    const pos = await a.get("auftragposition").fetch();
    soll = pos.get("mengeSoll") || 0;
    ist = pos.get("mengeIst") || 0;
    fertig = pos.get("fertig");
  }
  if (a.has("auftragsstuecklistenPosition")) {
    const listpos = await a.get("auftragsstuecklistenPosition").fetch();
    soll = listpos.get("mengeSoll") || 0;
    ist = listpos.get("mengeIst") || 0;
    fertig = listpos.get("fertig");
  }

  const b = new Parse.Object("open_transaction_artikel");
  if (!a.has("auftragsstuecklistenPosition") || !(ist >= soll || fertig)) {
    b.set("geplant", true);
  } else {
    b.set("geplant", false);
  }
  b.set("artikel", a.get("artikel"));
  b.set("auftrag", a.get("auftrag"));
  b.set("seriennummer", a.get("seriennummer"));
  b.set("charge", a.get("charge"));
  b.set("auftragposition", a.get("auftragposition"));
  b.set("auftragsstueckliste", a.get("auftragsstueckliste"));
  b.set("auftragsstuecklistenPosition", a.get("auftragsstuecklistenPosition"));
  b.set("lager", a.get("lager"));
  b.set("lagerort", a.get("lagerort"));
  b.set("user", Parse.User.current());
  b.set("menge", (menge || a.get("menge") || 0) * -1);

  b.set("undo", a);
  try {
    //await b.save();
    for (let i = 0; i < 10; i++) {
      await delay(1000);
      break; // hier löschen
      await b.fetch();

      if (b.get("gebucht")) {
        break;
      }
    }

    if (b.get("gebucht")) {
      if (b.get("successful")) {
        message.success("Erfolgreich gebucht");
      } else {
        Modal.error({
          title: "Fehler in der Buchung",
          content: _optionalChain([b, 'access', _27 => _27.get, 'call', _28 => _28("response"), 'optionalAccess', _29 => _29.answer]),
        });
      }
    } else {
      message.error("Auftrag wurde nicht gebucht");
    }
  } catch (error) {
    message.error("Rücklagerung fehlgeschlagen\n" + JSON.stringify(error));
  }
}

export function getListItem(item, lagerOrte, cb) {
  let unconfirmedRemovals = 0;
  let precisionSoll = _optionalChain([("" + item.mengeSoll), 'access', _30 => _30.split, 'call', _31 => _31("."), 'access', _32 => _32[1], 'optionalAccess', _33 => _33.length]) || 0;
  let precisionIst = _optionalChain([("" + item.mengeIst), 'access', _34 => _34.split, 'call', _35 => _35("."), 'access', _36 => _36[1], 'optionalAccess', _37 => _37.length]) || 0;

  const angefordert = item.mengeAngefordertNichtBestellt > 0;
  const bestellt = item.mengeBestelltWehn > 0;
  const mail = (
    React.createElement(Alert, {
      message: 
        bestellt
          ? "bestellt"
          : angefordert
          ? "angefordert"
          : "nicht angefordert"
      ,
      type: "warning",
      showIcon: true,
      icon: React.createElement(Icon, { icon: "fa:shopping-cart", __self: this, __source: {fileName: _jsxFileName, lineNumber: 270}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}
    )
  );
  const doneFlag =
    item.fertig &&
    !(
      (item.mengeAngefordertNichtBestellt > 0 || item.mengeBestelltWehn > 0) &&
      item.mengeIst == 0 &&
      item.mengeSoll == 0
    );

  const inProgressFlag = !doneFlag && item.mengeIst > 0;

  const mailFlag =
    item.mengeAngefordertNichtBestellt > 0 || item.mengeBestelltWehn > 0;
  const gesperrtFlag =
    !(item.mengeAngefordertNichtBestellt > 0 || item.mengeBestelltWehn > 0) &&
    item.gesperrt &&
    !item.fertig;
  const LItem = (
    React.createElement(List.Item, {
      className: item.gesperrt ? "item-disabled" : "",
      style: {
        paddingLeft: "3px",
        borderRight: gesperrtFlag
          ? "6px solid #DD0000"
          : doneFlag
          ? "6px solid #00cc00"
          : mailFlag
          ? "6px solid #fcc25c"
          : inProgressFlag
          ? "6px solid #fFee5c"
          : "6px solid gray",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
    
      , React.createElement(Row, {
        onClick: () => {
          cb(item);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}
      
        , React.createElement(Col, { span: 15, __self: this, __source: {fileName: _jsxFileName, lineNumber: 310}}
          , React.createElement(Space, { direction: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
            , React.createElement('div', { style: { paddingTop: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}
              , React.createElement('h3', { style: { margin: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
                , React.createElement(Icon, { icon: "fa:inventory", __self: this, __source: {fileName: _jsxFileName, lineNumber: 314}} )
                , (!_optionalChain([lagerOrte, 'access', _38 => _38[_optionalChain([item, 'access', _39 => _39.artikel, 'optionalAccess', _40 => _40.objectId])], 'optionalAccess', _41 => _41.lagerNr])
                  ? " Kein Bestand"
                  : " " +
                    lagerOrte[_optionalChain([item, 'access', _42 => _42.artikel, 'optionalAccess', _43 => _43.objectId])].lager
                      .match(/.{1,3}/g)
                      .join("-") +
                    (lagerOrte[_optionalChain([item, 'access', _44 => _44.artikel, 'optionalAccess', _45 => _45.objectId])].lagerOrt || ""
                      ? "/"
                      : "") +
                    (
                      (lagerOrte[_optionalChain([item, 'access', _46 => _46.artikel, 'optionalAccess', _47 => _47.objectId])].lagerOrt || "").match(
                        /.{1,3}/g
                      ) || []
                    ).join("-")
                )
                  .toString()
                  .replace(".", " - ")
              )
            )
            , React.createElement('h3', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 334}}
              , React.createElement(Icon, { icon: "fa:cube", __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}} )
              , item.artikelnr ? " " + item.artikelnr : " O-Teil"
            )
          )
        )
        , React.createElement(Col, {
          span: 8,
          onClick: () => {
            cb(item);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}
        
          , React.createElement(Space, {
            align: "center",
            style: {
              padding: "10px",
              width: "100%",
              //backgroundColor: "white",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}
          
            , 
              React.createElement(Statistic, {
                title: 
                  React.createElement(React.Fragment, null
                    , gesperrtFlag && React.createElement(Tag, { color: "#DD0000", __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}, "Gesperrt")
                    , doneFlag && React.createElement(Tag, { color: "#00cc00", __self: this, __source: {fileName: _jsxFileName, lineNumber: 359}}, "Fertig")
                    , mailFlag && !doneFlag && (
                      React.createElement(Tag, { color: "#fcc25c", __self: this, __source: {fileName: _jsxFileName, lineNumber: 361}}
                        , " "
                        , bestellt
                          ? "Bestellt"
                          : angefordert
                          ? "Angefordert"
                          : "Nicht angefordert"
                      )
                    )
                    , inProgressFlag && (
                      React.createElement(Tag, { color: "#fFee5c", style: { color: "grey" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 371}}, "Teilentnommen"

                      )
                    )

                    , !(
                      gesperrtFlag ||
                      doneFlag ||
                      mailFlag ||
                      inProgressFlag
                    ) && React.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 381}}, "Entnahme")
                  )
                ,
                value: " " + item.mengeIst.toFixed(precisionIst) || "0",
                suffix: 
                  "/ " +
                  item.mengeSoll.toFixed(precisionSoll) +
                  " " +
                  (_optionalChain([item, 'access', _48 => _48.artikel, 'optionalAccess', _49 => _49.mengeneinheit]) || "")
                ,
                prefix: 
                  React.createElement(React.Fragment, null
                    , gesperrtFlag && (
                      React.createElement(Icon, { style: { color: "#DD0000" }, icon: "fa:ban", __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}} )
                    )
                    , doneFlag && (
                      React.createElement(Icon, {
                        style: { color: "#00cc00" },
                        icon: "fa:check-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}}
                      )
                    )
                    , mailFlag && (
                      React.createElement(Icon, {
                        style: { color: "#fcc25c" },
                        icon: "fa:shopping-cart", __self: this, __source: {fileName: _jsxFileName, lineNumber: 403}}
                      )
                    )
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}}
              )
            
          )
        )
        , React.createElement('span', {
          style: {
            marginBottom: "4px",
            color: "rgba(0, 0, 0, 0.75)",
            fontSize: "13px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 414}}
        
          , item.bezeichnung
        )
      )
    )
  );
  if (
    item.mengeSoll > item.mengeIst &&
    !_optionalChain([lagerOrte, 'access', _50 => _50[_optionalChain([item, 'access', _51 => _51.artikel, 'optionalAccess', _52 => _52.objectId])], 'optionalAccess', _53 => _53.lagerNr])
  ) {
    return (
      React.createElement(Badge.Ribbon, { text: React.createElement(Icon, { icon: "fa:exclamation", __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}} ), color: "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}
        , LItem
      )
    );
  } else {
    return LItem;
  }
}

function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export async function getLagerOrteMap(artikelArray) {
  const einlagerungen = await new Parse.Query("ArtikelEinlagerung")
    .containedIn("artikel", artikelArray)
    .include("lager")
    .include("lagerort")
    .find();

  const bestaende = await new Parse.Query("ArtikelBestand")
    .include("lager")
    .containedIn("artikel", artikelArray)
    .find();

  const result = {};

  for (const bestand of bestaende) {
    const artikelId = _optionalChain([bestand, 'access', _54 => _54.get, 'call', _55 => _55("artikel"), 'optionalAccess', _56 => _56.id]);
    const lagerNr = parseInt(_optionalChain([bestand, 'access', _57 => _57.get, 'call', _58 => _58("lager"), 'optionalAccess', _59 => _59.get, 'call', _60 => _60("sqlRef")]));

    if (lagerNr > (_optionalChain([result, 'access', _61 => _61[artikelId], 'optionalAccess', _62 => _62.lagerNr]) || Number.MAX_VALUE)) {
      continue;
    }

    result[artikelId] = {
      lagerNr,
      lager: bestand.get("lager").get("sqlRef"),
      lagerName: bestand.get("lager").get("bezeichnung"),
    };
  }

  for (const einlagerung of einlagerungen) {
    const artikelId = einlagerung.get("artikel").id;
    const lagerNr = parseFloat(
      JSON.parse(_optionalChain([einlagerung, 'access', _63 => _63.get, 'call', _64 => _64("lagerort"), 'optionalAccess', _65 => _65.get, 'call', _66 => _66("sqlRef")]) || "[0,0]").join(
        "."
      )
    );
    const existingLagerNr = _optionalChain([result, 'access', _67 => _67[artikelId], 'optionalAccess', _68 => _68.lagerNr]) || Number.MAX_VALUE;

    if (Number.isInteger(existingLagerNr)) {
      if (Math.floor(lagerNr) > existingLagerNr) {
        continue;
      }
    } else {
      if (lagerNr > existingLagerNr) {
        continue;
      }
    }

    result[artikelId] = {
      lagerNr,
      lagerName: einlagerung.get("lager").get("bezeichnung"),
      lagerOrt: _optionalChain([einlagerung, 'access', _69 => _69.get, 'call', _70 => _70("lagerort"), 'optionalAccess', _71 => _71.get, 'call', _72 => _72("lagerort")]) || "Kein Lagerort",
      lager: einlagerung.get("lager").get("sqlRef"),
    };
  }

  return result;
}
