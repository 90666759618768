const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelEntnahmeRoute.tsx";import * as React from "react";

import { observer } from "mobx-react-lite";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ArtikelEntnahme } from "./ArtikelEntnahme";
import { Divider, PageHeader } from "antd";

export const ArtikelEntnahmeRoute = observer(function ArtikelEntnahmeRoute() {
  const { type, id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onCancel = () => {
    const redirect = searchParams.get("redirect");
    if (redirect) {
      navigate(redirect);
    } else {
      window.history.back();
    }
  };

  const onSave = () => {
    onCancel();
  };

  if (type === "Auftragsposition" && id) {
    return (
      React.createElement(Layout, { onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , React.createElement(ArtikelEntnahme, {
          config: { type: "Auftragsposition", id },
          onSave: onSave,
          onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
      )
    );
  }

  if (type === "AuftragsstuecklistenPosition" && id) {
    return (
      React.createElement(Layout, { onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , React.createElement(ArtikelEntnahme, {
          config: { type: "AuftragsstuecklistenPosition", id },
          onSave: onSave,
          onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      )
    );
  }

  if (type === "Artikel" && id) {
    const auftrag = searchParams.get("auftrag");
    const aufpos = searchParams.get("aufpos");
    const stckliste = searchParams.get("stckliste");

    if (!auftrag || !aufpos || !stckliste) {
      return null;
    }

    return (
      React.createElement(Layout, { onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , React.createElement(ArtikelEntnahme, {
          config: {
            type: "Artikel",
            artikelId: id,
            auftragId: auftrag,
            auftragpositionId: aufpos,
            stückliste: stckliste,
          },
          onSave: onSave,
          onCancel: onCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
      )
    );
  }

  return null;
});

const Layout = React.memo(
  function Layout({ children, onCancel }) {
    return (
      React.createElement('div', {
        style: {
          background: "#fff",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
      
        , React.createElement(PageHeader, {
          title: "Buchung",
          style: { background: "#fff", paddingBottom: "5px" },
          extra: [],
          onBack: () => {
            onCancel();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )

        , React.createElement('div', { style: { padding: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}, children)
      )
    );
  }
);
