const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\Global.tsx";import * as React from "react";

import { observer } from "mobx-react-lite";



import { ArtikelEntnahmeDrawer } from "../components/ArtikelEntnahmeDrawer";

export const Global = observer(function Global() {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(ArtikelEntnahmeDrawer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} )
    )
  );
});
