import Parse from "parse";






















export class Artikel extends Parse.Object {
  constructor(data) {
    super("Artikel", data );
  }
}

Parse.Object.registerSubclass("Artikel", Artikel);
