const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\forms\\FormPage.js";import React, { } from "react";


import { useParams } from "react-router-dom";

import TextInput from "./components/TextInput";
import NumberInput from "./components/NumberInput";
import InfoMessage from "./components/InfoMessage";
import ImageCam from "./components/ImageCam";
import CheckBox from "./components/CheckBox";
import SelectBox from "./components/SelectBox";
import AuftragsstuecklisteComponent from "../custom_wehn/AuftragsstuecklisteComponent";
import AuftragspositionComponent from "../custom_wehn/AuftragspositionComponent";
import TimeSheetComponent from "../custom_wehn/TimeSheetComponent";
import AutoComplete from "./components/AutoComplete";
import TimePicker from "./components/TimePicker";
import StartStop from "./components/StartStop";
import ShelfSelect from "../custom_arfa/ShelfSelect";

function FormPage(props) {
  const params = useParams();
  const formData = props.FormData;

  if (!FormPage.generators) {
    FormPage.generators = {};
  }
  const getFormElement = (value, index) => {
    let element = "";
    if (value.type.toLowerCase() === "stockremovalpos") {
      element = (
        React.createElement(AuftragspositionComponent, {
          key: "element-" + index,
          Data: value,
          SetExtras: props.SetExtras,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
      );
    }
    if (value.type.toLowerCase() === "stockremoval") {
      element = (
        React.createElement(AuftragsstuecklisteComponent, {
          key: "element-" + index,
          Data: value,
          SetExtras: props.SetExtras,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      );
    }
    if (value.type.toLowerCase() === "shelfselect") {
      element = (
        React.createElement(ShelfSelect, {
          FormData: formData,
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        )
      );
    }
    if (value.type.toLowerCase() === "timesheet") {
      element = (
        React.createElement(TimeSheetComponent, {
          key: "element-" + index,
          Data: value,
          SetExtras: props.SetExtras,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )
      );
    }
    if (value.type.toLowerCase() === "text") {
      element = (
        React.createElement(TextInput, {
          key: "element-" + index,
          Data: value,
          Default: value.default,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
        )
      );
    }
    if (value.type.toLowerCase() === "number") {
      element = (
        React.createElement(NumberInput, {
          key: "element-" + index,
          Data: value,
          Default: value.default,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        )
      );
    }
    if (value.type.toLowerCase() === "info") {
      element = (
        React.createElement(InfoMessage, {
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
        )
      );
    }
    if (value.type.toLowerCase() === "check_box") {
      element = (
        React.createElement(CheckBox, {
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        )
      );
    }
    if (value.type.toLowerCase() === "select") {
      element = (
        React.createElement(SelectBox, {
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
        )
      );
    }

    if (value.type.toLowerCase() === "choose_image") {
      element = (
        React.createElement(ImageCam, {
          key: "element-" + index,
          Data: value,
          Default: value.default,
          FormData: formData,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
        )
      );
    }
    if (value.type.toLowerCase() === "autocomplete") {
      element = (
        React.createElement(AutoComplete, {
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
        )
      );
    }
    if (value.type.toLowerCase() === "duration") {
      element = (
        React.createElement(TimePicker, {
          FormData: formData,
          key: "element-" + index,
          ShowSeconds: value.options.seconds,
          Data: value,
          Mode: value.options.mode || "complete",
          Start: new Date().getTime(),
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
        )
      );
    }
    if (value.type.toLowerCase() === "startstop") {
      element = (
        React.createElement(StartStop, {
          FormData: formData,
          key: "element-" + index,
          Data: value,
          ShowNextButton: props.ShowNextButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
        )
      );
    }

    return element;
  };

  return (
    React.createElement('div', { className: "form_container", style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
      , props.Data.forms[params.entry].data.fields.map((value, index) => {
        return getFormElement(value, index);
      })

      , React.createElement('div', { style: { height: "100px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}})
    )
  );
}

export default FormPage;
