const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_arfa\\ShelfSelect.js";import React, { } from "react";
import Parse from "parse";
import produce from "immer";
import { Form, Select } from "antd";
import "antd/dist/antd.css";

function ShelfSelect(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  let state = props.Data;
  const data = props.key;
  const formData = props.FormData;
  const [items, setItems] = React.useState(state.items || []);
  const [shelf, setShelf] = React.useState("");

  let rules = [];
  const initList = async () => {
    try {
      const shelfOptions = await new Parse.Query("ARFA_CUSTOMER")
        .equalTo("name", localStorage.getItem("openchecklistref"))
        .first();
      const listOptions = await new Parse.Query("ARFA_SHELF")
        .equalTo("customer", shelfOptions.id)
        .limit(999999)
        .find();

      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.id,
            label:
              parseOption.get("Regalnummer") + " - " + parseOption.get("name"),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    initList();
  }, []);

  React.useEffect(() => {
    let x = {};
    if (shelf != "") {
      x[data.key] = shelf;
      formData.setFieldsValue(x);
    }
  }, [shelf]);

  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }
  let mergedStyle = { ...{ heigt: "10em" }, ...style };

  return (
    React.createElement(Form.Item, { name: state.key, label: state.title, rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      , React.createElement(Select, {
        className: "bde-autocomplete",
        showSearch: true,
        style: mergedStyle,
        options: items,
        listHeight: 300,
        onChange: (e) => {
          setShelf(
            produce((draft) => {
              draft = e;
            })
          );
        },
        filterOption: (input, option) =>
          JSON.stringify(option).toLowerCase().indexOf(input.toLowerCase()) >= 0
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      )
    )
  );
}
export default ShelfSelect;
