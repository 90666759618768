import Parse from "parse";














export class Lagerort extends Parse.Object {
  constructor(data) {
    super("Lagerort", data );
  }
}

Parse.Object.registerSubclass("Lagerort", Lagerort);
