const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\components\\ArtikelInfoShowRoute.tsx";import * as React from "react";

import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { ArtikelInfoShow } from "./ArtikelInfoShow";

export const ArtikelInfoShowRoute = observer(function ArtikelInfoShowRoute() {
  const { artikel: artikelId } = useParams();

  return React.createElement(ArtikelInfoShow, { artikelId: artikelId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}} );
});
