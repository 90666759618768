const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\forms\\components\\TimePicker.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState } from "react";

import produce from "immer";
import {



  Divider,

  Radio,
  Select,
  Input,
  Form,
} from "antd";
import moment from "moment";




const { Option } = Select;
export default (props) => {
  const data = props.Data;
  const formData = props.FormData;
  let rules = [];
  if (data["v_required"]) {
    rules.push({
      required: data["v_required"].value,
      message: data["v_required"].err,
    });
  }
  const start = moment(props.Start);
  if (props.Mode == "single") {
    start.set("hours", 0);
    start.set("minutes", 0);
    start.set("seconds", 0);
  }
  const [pickerState, setPickerState] = useState({
    mode: props.Mode || "single",

    now: start,
    end: props.End
      ? moment(props.End)
      : moment(props.Start).add(Math.min(_optionalChain([props, 'access', _ => _.Item, 'optionalAccess', _2 => _2.sollzeit]) || 1, 8), "hour"),
  });
  React.useEffect(() => {
    let x = {};
    if (pickerState.mode == "single") {
      x[data.key] =
        pickerState.now.hours() * (1000 * 60 * 60) +
        pickerState.now.minutes() * (1000 * 60);
      if (props.ShowSeconds) {
        x[data.key] += pickerState.now.seconds() * 1000;
      }
    } else {
      x[data.key] = pickerState.now.diff(pickerState.end) * -1;
    }

    formData.setFieldsValue(x);
  }, [pickerState.now, pickerState.end, pickerState.mode]);

  const getPicker = (end) => {
    const now = !end ? pickerState.now : pickerState.end;
    if (!now) return "";

    const prefix = !end ? props.PrefixStart : props.PrefixEnd;
    const suffix = !end ? props.SuffixStart : props.SuffixEnd;
    const seconds = props.ShowSeconds;
    const setHours = (e) => {
      setPickerState(
        produce((draft) => {
          let newNow = moment(now);
          newNow.set({ hour: e });
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    const setMinutes = (e) => {
      setPickerState(
        produce((draft) => {
          let newNow = moment(now);
          newNow.set({ minute: e });
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    const setSeconds = (e) => {
      setPickerState(
        produce((draft) => {
          let newNow = moment(now);
          newNow.set({ seconds: e });
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    return (
      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
        , end ? React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 109}} ) : ""
        , React.createElement('div', { className: "timespan_picker_single", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          , prefix
          , React.createElement(Select, {
            listItemHeight: 80,
            listHeight: 320,
            size: "large",
            value: now.hours(),
            onChange: setHours,
            bordered: false,
            suffixIcon: "Std", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
          
            , [...Array(24).keys()].map((hour) => {
              return (
                React.createElement(Option, { className: "select_option", value: hour, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
                  , hour < 10 ? "0" + hour : hour
                )
              );
            })
          )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}, ":")
          , React.createElement(Select, {
            className: "timepicker",
            listItemHeight: 80,
            listHeight: 320,
            size: "large",
            value: now.minutes(),
            onChange: setMinutes,
            bordered: false,
            suffixIcon: "Min", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
          
            , [...Array(60).keys()].map((min) => {
              return (
                React.createElement(Option, { className: "select_option", value: min, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
                  , min < 10 ? "0" + min : min
                )
              );
            })
          )
          , seconds ? (
            React.createElement(React.Fragment, null
              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}, ":")
              , React.createElement(Select, {
                className: "timepicker",
                listItemHeight: 80,
                listHeight: 320,
                size: "large",
                value: now.seconds(),
                onChange: setSeconds,
                bordered: false,
                suffixIcon: "Sek", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
              
                , [...Array(60).keys()].map((sec) => {
                  return (
                    React.createElement(Option, { className: "select_option", value: sec, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
                      , sec < 10 ? "0" + sec : sec
                    )
                  );
                })
              )
            )
          ) : (
            ""
          )
          , suffix
        )
      )
    );
  };
  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}
      , React.createElement(Form.Item, {
        className: "hidden_custom_form",
        name: data.key,
        label: data.title,
        rules: rules,
        style: { marginBottom: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
      
        , React.createElement(Input, { style: { display: "none" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}} )
      )
      , React.createElement('div', { className: "timepicker_drawer", style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
        , React.createElement(Radio.Group, {
          style: { width: "100%" },
          options: [
            { label: "Dauer", value: "single" },
            { label: "Start & Ende", value: "complete" },
          ],
          onChange: (e) => {
            setPickerState(
              produce((draft) => {
                draft.mode = e.target.value;
              })
            );
          },
          value: pickerState.mode,
          optionType: "button",
          buttonStyle: "solid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
        )
        , React.createElement('div', {
          className: "timespan_picker",
          style: {
            margin: "20px",
            width: "calc(100% - 40px)",
            textAlign: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
        
          , pickerState.mode === "complete" ||
          !pickerState.saveObj ||
          pickerState.saveObj.get("end")
            ? getPicker()
            : ""
          , pickerState.mode === "complete" || pickerState.saveObj
            ? getPicker(true)
            : ""
        )
      )
    )
  );
};
