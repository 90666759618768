import Parse from "parse";



















export class AuftragsarbeitsplaenePosition extends Parse.Object {
  constructor(data) {
    super("AuftragsarbeitsplaenePosition", data );
  }
}

Parse.Object.registerSubclass("AuftragsarbeitsplaenePosition", AuftragsarbeitsplaenePosition);
