const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\components\\Login.js";import React from "react";
import { Form, Input, Button, notification } from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import Parse from "parse";
import Icon from "@ant-design/icons";

import { useNavigate } from "react-router";

const FormItem = Form.Item;
const logo = require("../logo512.png");
function Login() {
  const navigate = useNavigate();
  return (
    React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , React.createElement('div', {
        style: {
          paddingTop: "30px",
          paddingBottom: "30px",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      
        , React.createElement('img', { height: "250", alt: "app-icon", src: logo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}})
      )
      , React.createElement(Form, {
        onFinish: (e) => {
          login(e.userName, e.password, navigate);
        },
        className: "login-form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      
        , React.createElement(FormItem, { name: "userName", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          , React.createElement(Input, {
            prefix: React.createElement(Icon, { type: "user", style: { fontSize: 13 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} ),
            placeholder: "Username", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
          )
        )

        , React.createElement(FormItem, { name: "password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          , React.createElement(Input, {
            prefix: React.createElement(Icon, { type: "lock", style: { fontSize: 13 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} ),
            type: "password",
            placeholder: "Password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          )
        )

        , React.createElement(FormItem, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , React.createElement(Button, {
            type: "primary",
            htmlType: "submit",
            className: "login-form-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , "Log in"

          )
        )
      )
    )
  );
}
export default Login;

async function login(username, password, navigate) {
  username = username.toLowerCase();

  try {
    try {
      await Parse.User.logIn(username, password);
    } catch (error) {
      const user = await Parse.Cloud.run("ldap_login", {
        username,
        password,
      });

      if (!user && !user.session) {
        throw new Error("LDAP_LOGIN_ERROR");
      }

      await Parse.User.become(user.session);
    }

    openNotification(
      false,
      "Anmelden erfolgreich",
      "Sie werden in kürze umgeleitet..."
    );

    setTimeout(() => {
      navigate("/select");
    }, 2000);
  } catch (error) {
    console.error(error);

    openNotification(
      true,
      "Login nicht möglich",
      "Kombination aus Email und Passwort ist falsch."
    );
  }
}

function openNotification(error, title, msg) {
  notification.open({
    message: title,
    description: msg,
    icon: error ? (
      React.createElement(CloseOutlined, { style: { color: "#e63541" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
    ) : (
      React.createElement(InfoCircleOutlined, { style: { color: "#108ee9" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
    ),
  });
}
