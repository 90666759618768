const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\forms\\components\\NumberInput.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";

import { Form, InputNumber, Typography } from "antd";


const { Text } = Typography;

function NumberInput(props) {
  let style = { width: "100%", display: "inline-block" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  let state = props.Data;

  const getDecimalSeperator = () => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(navigator.languages[0])
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === "decimal").value;
  };

  let rules = [];
  let options = state.options || {};
  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        if (options.validation.max || options.validation.max === 0) {
          if (value > options.validation.max)
            return Promise.reject(
              options.validation.err + ": <" + options.validation.max
            );
        }
        if (options.validation.min || options.validation.min === 0) {
          if (value < options.validation.min)
            return Promise.reject(
              options.validation.err + ": >" + options.validation.min
            );
        }
        return Promise.resolve();
      },
    }));
  }

  return (
    React.createElement(Form.Item, {
      name: state.key,
      label: 
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
          , state.title
          , React.createElement(Text, { style: { display: "inline-block" }, code: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            , state.options.unit
          ), " "
        )
      ,
      rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
    
      , React.createElement(InputNumber, {
        style: style,
        type: "text",
        inputMode: "decimal",
        step: "1",
        pattern: "[0-9]+([\\.,][0-9]+)?",
        defaultValue: props.Default,
        decimalSeparator: getDecimalSeperator(),
        placeholder: state.hint,
        min: _optionalChain([state, 'access', _ => _.options, 'access', _2 => _2.validation, 'optionalAccess', _3 => _3.min]) || Number.MIN_SAFE_INTEGER,
        max: _optionalChain([state, 'access', _4 => _4.options, 'access', _5 => _5.validation, 'optionalAccess', _6 => _6.max]) - 1 || Number.MAX_SAFE_INTEGER,
        value: state.value,
        size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      )
    )
  );
}
export default NumberInput;
