import Parse from "parse";













export class Auftrag extends Parse.Object {
  constructor(data) {
    super("Auftrag", data );
  }
}

Parse.Object.registerSubclass("Auftrag", Auftrag);
