import Parse from "parse";





























export class AuftragsstuecklistenPosition extends Parse.Object {
  constructor(data) {
    super("AuftragsstuecklistenPosition", data );
  }
}

Parse.Object.registerSubclass("AuftragsstuecklistenPosition", AuftragsstuecklistenPosition);
