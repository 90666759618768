import Parse from "parse";

















export class ArtikelEinlagerung extends Parse.Object {
  constructor(data) {
    super("ArtikelEinlagerung", data );
  }
}

Parse.Object.registerSubclass("ArtikelEinlagerung", ArtikelEinlagerung);
