import Parse from "parse";











export class BuchungExtern extends Parse.Object {
  constructor(data) {
    super("BuchungExtern", data );
  }
}

Parse.Object.registerSubclass("BuchungExtern", BuchungExtern);
